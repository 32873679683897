























































































































































































































import CatalogPage from './CatalogPage';
export default CatalogPage;
