import { Vue, Component, Prop } from 'vue-property-decorator';
import * as CarouselLeft from '../../assets/images/cms/CarouselLeft.svg';
import * as CarouselRight from '../../assets/images/cms/CarouselRight.svg';
import CmsCardComponent from '@/commoncomponents/CmsCardComponent/CmsCardComponent.vue';
import { getStatusState } from '../CmsCardComponent/CmsCardComponent';
import { ContentCardData, CourseStatus } from '@/utilities/cmsUtilities';

@Component({
  name: 'card-carousel',
  props: {
    cardDataArr: {
      type: Array
    },
    showFilters: {
      type: Boolean
    },
    title: {
      type: String
    }
  },
  components: {
    'cms-card': CmsCardComponent
  }
})
export default class CmsCardCarousel extends Vue {
  @Prop({
    type: Array
  })
  cardDataArr!: Array<ContentCardData>;

  @Prop({
    type: Boolean,
    default: () => true
  })
  showFilters!: boolean;

  @Prop({
    type: String
  })
  title!: String;

  filterOptions: Array<any> = [];

  currentFilters: Array<CourseStatus> = [];

  filteredCards: Array<ContentCardData> = [...this.cardDataArr];

  index = 0;
  offset = '0px';
  offsetAmmount = 0;

  showPrev: boolean = false;
  showNext: boolean = true;
  leftArrowIcon: string = CarouselLeft;
  rightArrowIcon: string = CarouselRight;

  touchstartX = 0;
  touchendX = 0;
  displayableCards = 0;

  move(ammount: number) {
    this.index += ammount;
    //If index is 1 we need to offset for padding
    if (this.index === 1) {
      this.offset = `-${this.offsetAmmount * this.index + 16}px`;
    }
    else {
      this.offset = `-${this.offsetAmmount * this.index}px`;
    }
    if (this.index > 0) {
      this.showPrev = true;
    }
    else {
      this.showPrev = false;
    }
    if (this.index < this.filteredCards.length - 1) {
      this.showNext = true;
    }
    else {
      this.showNext = false;
    }
    if (this.index + this.displayableCards > this.filteredCards.length) {
      this.showNext = false;
    }
    if (this.displayableCards >= this.cardDataArr.length) {
      this.showNext = false;
    }
  }

  moveRight() {
    this.move(1);
  }

  moveLeft() {
    if (this.index > 0) {
      this.move(-1);
    }
  }

  setWidth(carouselEl: HTMLElement) {
    const parent = carouselEl.parentElement;
    const parentWidth = parent
      ? parent.clientWidth
      : 0;
    this.displayableCards = Math.floor(parentWidth / this.offsetAmmount);
    const newWidth = this.displayableCards * this.offsetAmmount + 20;
    carouselEl.style.setProperty('--carousel-width', `${newWidth}px`);
    if (this.displayableCards >= this.cardDataArr.length) {
      this.showNext = false;
    }
    else {
      this.showNext = true;
    }
  }

  handleResize(e: any) {
    const carousel: any = this.$refs.carouselContainer;
    this.setWidth(carousel);
  }

  handleSwipeLeft(e: any) {
    this.moveRight();
  }

  handleSwipeRight(e: any) {
    this.moveLeft();
  }

  created() {
    window.addEventListener('resize', this.handleResize);
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }

  filterBy(val: CourseStatus) {
    if (this.currentFilters.includes(val)) {
      this.currentFilters = this.currentFilters.filter(el => el !== val);
    }
    else {
      this.currentFilters.push(val);
    }

    const newFiltered = this.currentFilters.length
      ? this.cardDataArr.filter(el =>
        this.currentFilters.includes(el.courseStatus as CourseStatus)
      )
      : [...this.cardDataArr];
    Vue.set(this, 'filteredCards', newFiltered);
    this.move(0 - this.index);
  }

  beforeMount() {
    const allStatuses = this.cardDataArr
      .map(item => item.courseStatus)
      .filter(item => item !== undefined);
    const uniqueStatuses = [...new Set(allStatuses)];
    const filters = uniqueStatuses.map(st => ({
      value: st,
      label: getStatusState(st).statusText,
      count: allStatuses.filter(el => el === st).length
    }));
    this.filterOptions = filters;
  }

  mounted() {
    const cardWidth = 253 + 16;
    this.offsetAmmount = cardWidth;
    const carousel: any = this.$refs.carouselContainer;
    const originalWidth = carousel && carousel.offsetWidth;
    if (carousel && originalWidth) {
      this.setWidth(carousel);
    }
  }
}
