import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BreadcrumbComponent extends Vue {
    public highestRole: number = APP_CONST.MINUS_ONE;

    @Prop({ default: 'blue' })
    theme!: string;

    @Prop({ default: [''] })
    breadcrumbs!: [];

    /* istanbul ignore next */
    get filteredBreadcrumbs() {
      if (this.highestRole > APP_CONST.THREE) {
        const newBreadcrums = this.breadcrumbs.filter((element) => element !== 'Partner Accounts');
        return newBreadcrums;
      }
      else {
        return this.breadcrumbs;
      }
    }

    /* istanbul ignore next */
    beforeMount() {
      this.highestRole = Number(APP_UTILITIES.getCookie('highest_role'));
    }

    navigateTo(path: string) {
      switch (path) {
        case 'Account':
          this.$router.push('/account/programs');
          break;

        case 'Site/Session':
          this.$router.push('/account/program/sites');
          break;

        case 'Partner Accounts':
          this.$router.push('/home');
          break;

        default:
          break;
      }
    }
}
